<template>
  <div>
    <Modal >
      <form @submit.prevent="onSubmit">
        <div class="title"> {{ !aclaracion.folio_atencion ? 'Crear nueva aclaración' : 'Información de la aclaración' }}</div>

        <div v-if="!aclaracion.folio_atencion">
          <div class="alert alert-primary mx-3 my-3" role="alert">
            <i class="fa-solid fa-circle-info"></i>
            Para garantizar que la generación de la aclaración se procese adecuadamente, por favor, asegúrate de proporcionar
            información completa y precisa en todos los campos del formulario.
          </div>
        </div>

        <div class="body">

          <div  v-if="pagina === 1">
            <h5>
              Información general
              <br>
              <small class="text-secondary">(Paso 1/3)</small>
            </h5>

            <div class="row justify-center">

              <div class="col-md-4 my-3">
                <label for="trimestre_presentar">Trimestre</label>
                <select v-model="aclaracion.trimestre" name="trimestre_presentar" id="trimestre_presentar"
                        class="form-control" :disabled="!editable">
                  <option :value="num_trimestre" v-for="(trimestre, num_trimestre) of trimestres">{{trimestre}}</option>
                </select>
              </div>

              <div class="col-md-4 my-3">
                <label for="año_presentar">Año</label>
                <select v-model="aclaracion.trimestre_ano" name="año_presentar" id="año_presentar"
                        class="form-control" :disabled="!editable">
                  <option :value="ano_actual-1">{{ano_actual-1}}</option>
                  <option :value="ano_actual">{{ano_actual}}</option>
                </select>
              </div>

              <div class="col-md-4 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="De acuerdo a la disposición siempre es el valor de “1”"><i class="fa fa-info-circle"></i></button>
                <label for="numero_aclaracion">Número de aclaración</label>
                <input v-model="aclaracion.numero_aclaracion" type="text" name="numero_aclaracion" id="numero_aclaracion"
                       class="form-control" disabled>
              </div>

            </div>

            <div class="row justify-center">

              <div class="col-md-6 my-3">
                <label for="fecha_aclaracion">Fecha de aclaración</label>
                <input v-model="aclaracion.fecha_aclaracion" type="date" name="fecha_aclaracion"
                       id="fecha_aclaracion" class="form-control" :disabled="!editable">
              </div>

              <div class="col-md-6 my-3">
                <label for="medio_de_recepcion">Medio de recepción</label>
                <select v-model="aclaracion.medio_de_recepcion" name="medio_de_recepcion"
                        id="medio_de_recepcion" class="form-control" :disabled="!editable">
                  <option v-for="medio in medios_recepciones" :value="medio.valor" :key="medio.valor">
                    {{ medio.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3" v-if="aclaracion.medio_de_recepcion == 6 || aclaracion.medio_de_recepcion == 7">
              <label for="folio_condusef">Folio CONDUSEF</label>
                <input id="folio_condusef" type="text" class="form-control" v-model="aclaracion.folio_condusef" :disabled="!editable" maxlength="50">
              </div>

              <div class="col-md-6 my-3" v-if="aclaracion.medio_de_recepcion == 6">
                <label for="re">ReversaSIGE</label>
                <select v-model="aclaracion.reversaSIGE" name="reversaSIGE"
                        id="reversaSIGE" class="form-control" :disabled="!editable ">
                  <option value="0">NO es reversa SIGE (Gestión electrónica)</option>
                  <option value="1">Si es reversa SIGE (Gestión electrónica)</option>
                </select>
              </div>


              <div class="col-md-6 my-3">
                <label for="prioridad">Prioridad</label>
                <select v-model="aclaracion.prioridad" name="prioridad" id="prioridad" class="form-control"
                        :disabled="!editable">
                  <option value="SI">Si</option>
                  <option value="NO">No</option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="estatus_aclaracion">Estado de la aclaración</label>
                <select v-model="aclaracion.estatus_aclaracion" name="estatus_aclaracion" id="estatus_aclaracion"
                        class="form-control" :disabled="!editable" @change="cambio_estatus_aclaracion()">
                  <option value="1">Pendiente</option>
                  <option value="2">Concluido</option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo solo es requerido cuando el estatús de aclaración es concluido"><i class="fa fa-info-circle"></i></button>
                <label for="nivel_atencion">Nivel de atención</label>
                <select v-model="aclaracion.nivel_atencion" name="nivel_atencion"
                        id="nivel_atencion" class="form-control" :disabled="!editable || aclaracion.estatus_aclaracion == estatus_pendiente">
                  <option v-for="nivel in niveles_atenciones" :value="nivel.valor" :key="nivel.valor">
                    {{ nivel.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo solo es requerido cuando el estatús de aclaración es concluido"><i class="fa fa-info-circle"></i></button>
                <label for="fecha_atencion">Fecha de atención</label>
                <input v-model="aclaracion.fecha_atencion" type="date" name="fecha_atencion"
                       id="fecha_atencion" class="form-control" :disabled="!editable || aclaracion.estatus_aclaracion == estatus_pendiente">
              </div>


              <div class="col-md-6 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo solo es requerido cuando el estatús de aclaración es concluido"><i class="fa fa-info-circle"></i></button>
                <label for="fecha_resolucion">Fecha de resolución</label>
                <input v-model="aclaracion.fecha_resolucion" type="date" name="fecha_resolucion"
                       id="fecha_resolucion" class="form-control" :disabled="!editable || aclaracion.estatus_aclaracion == estatus_pendiente">
              </div>

              <div class="col-md-6 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo solo es requerido cuando el estatús de aclaración es concluido"><i class="fa fa-info-circle"></i></button>
                <label for="fecha_notificacion_usuario">Fecha de notificación usuario</label>
                <input v-model="aclaracion.fecha_notificacion_usuario" type="date" name="fecha_notificacion_usuario"
                       id="fecha_notificacion_usuario" class="form-control" :disabled="!editable || aclaracion.estatus_aclaracion == estatus_pendiente">
              </div>


            </div>
          </div>

          <div v-if="pagina === 2">


            <h5>
              Detalles de la aclaración
              <br>
              <small class="text-secondary">(Paso 2/3)</small>
            </h5>


            <div class="row justify-center">



              <div class="col-md-6 my-3">
                <label for="producto_servicio">Producto servicio</label>
                <select v-model="aclaracion.producto_servicio" name="producto_servicio"
                        id="producto_servicio" class="form-control" :disabled="!editable">
                  <option v-for="producto in productos_servicios" :value="producto.valor"
                          :key="producto.valor">
                    {{ producto.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="causa">Causa o motivo</label>
                <select v-model="aclaracion.causa" name="causa" id="causa"
                        class="form-control" :disabled="!editable">
                  <option v-for="causa in causas_financieras" :value="causa.valor">
                    {{ causa.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="tipo_persona">Tipo de Persona</label>
                <select v-model="aclaracion.tipo_persona" name="tipo_persona" id="tipo_persona" class="form-control"
                        :disabled="!editable"
                        @change="cambio_tipo_persona()"
                >
                  <option value="1">Persona Física</option>
                  <option value="2">Persona Moral</option>
                </select>
              </div>

              <template v-if="aclaracion.tipo_persona == persona_fisica">
                <div class="col-md-6 my-3">
                  <label for="sexo">Sexo</label>
                  <select v-model="aclaracion.sexo" name="sexo" id="sexo" class="form-control"
                          :disabled="!editable">
                    <option value="H">Hombre</option>
                    <option value="M">Mujer</option>
                  </select>
                </div>


                <div class="col-md-6 my-3">
                  <label for="edad">Edad</label>
                  <input v-model="aclaracion.edad" type="number" name="edad" id="edad" class="form-control"
                         :disabled="!editable">
                </div>
              </template>

              <div class="col-md-6 my-3">
                <label for="operacion_extranjero">Operación extranjero</label>
                <select v-model="aclaracion.operacion_extranjero" name="operacion_extranjero" id="operacion_extranjero" class="form-control"
                        :disabled="!editable">
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="aclaracion_monetario">Aclaración monetaria</label>
                <select v-model="aclaracion.aclaracion_monetario" name="aclaracion_monetario" id="aclaracion_monetario" class="form-control"
                        :disabled="!editable">
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </div>

              <template v-if="aclaracion.aclaracion_monetario == 'SI'">

                <div class="col-md-6 my-3">
                  <label for="monto_reclamado">Monto reclamado</label>
                  <IC v-model="aclaracion.monto_reclamado" label="$" id="monto_reclamado" name="monto_reclamado" :formats="['moneyFormat']" :disabled="!editable"/>
                </div>

              </template>

            </div>
          </div>

          <div v-if="pagina === 3">

            <h5>
              Detalles de la aclaración
              <br>
              <small class="text-secondary">(Paso 3/3)</small>
            </h5>

            <div class="row justify-center">
              <div class="col-md-6 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo es requerido solo si el medio de recepción es UNE, Sucursal u Oficina de atención al público"><i class="fa fa-info-circle"></i></button>
                <label for="codigo_postal">Codigo postal</label>
                <input v-model="aclaracion.codigo_postal" @blur="buscar_codigo_postal" type="text"
                       maxlength="5" name="codigo_postal" id="codigo_postal" class="form-control"
                       :disabled="!editable">
              </div>

              <div class="col-md-6 my-3">
                <label for="entidad">Estado</label>
                <select v-model="aclaracion.entidad" name="entidad" id="entidad" class="form-control"
                        :disabled="!editable">
                  <option v-for="estado in estados" :value="estado.valor" :key="estado.valor">
                    {{ estado.nombre }}</option>
                </select>
              </div>


              <div class="col-md-6 my-3">
                <label for="municipio">Delegación / Municipio</label>
                <select v-model="aclaracion.municipio" name="municipio" id="municipio" class="form-control"
                        :disabled="!editable">
                  <option v-for="municipio in municipios" :value="municipio.valor" :key="municipio.valor">
                    {{ municipio.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="colonia">Colonia <small class="text-secondary">(opcional)</small></label>
                <select v-model="aclaracion.colonia" name="colonia" id="colonia" class="form-control"
                        :disabled="!editable">
                  <option v-for="colonia in colonias" :value="colonia.valor" :key="colonia.valor">
                    {{ colonia.nombre }}
                  </option>
                </select>
              </div>


              <div class="col-md-6 my-3">
                <label for="localidad">Localidad <small class="text-secondary">(opcional)</small></label>
                <select v-model="aclaracion.localidad" name="localidad" id="localidad" class="form-control"
                        :disabled="!editable">
                  <option v-for="localidad in localidades" :value="localidad.valor"
                          :key="localidad.valor">{{ localidad.nombre }}
                  </option>
                </select>
              </div>

            </div>


          </div>

        </div>

        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-secondary mr-2" type="button" @click="anterior"
                      :disabled="pagina === 1">
                <i class="fa-solid fa-arrow-left"></i>
                Anterior
              </button>
              <button class="btn btn-primary mr-2" type="button" @click.prevent="show_modal_confirmar_crear = true"
                      v-if="(pagina === 3 && !aclaracion.id) || (aclaracion.id && evento_id)">
                <i class="fa-solid fa-floppy-disk"></i>
                {{ evento_id ? 'Actualizar' : 'Guardar' }}
              </button>

              <button class="btn btn-secondary mr-2" type="button" @click="siguiente"
                       v-if="pagina !== 3">
                Siguiente
                <i class="fa-solid fa-arrow-right"></i>
              </button>

              <br>
              <button class="btn btn-danger mt-3 mr-2" type="button" @click.prevent="$emit('close')">
                <i class="fa-solid fa-xmark"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>

      </form>

      <!--Modal Confirmar datos -->
      <Modal v-if="show_modal_confirmar_crear" :options="{ width: '30vw', type: 'danger' }">
        <div class="title"> <i class="fa fa-warning mr-2"></i> Mensaje importante</div>
        <div class="body">
          <h5 class="font-weight-bold">
            ¿Estás seguro de que todos los datos han sido cargados correctamente en el formulario?
          </h5>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2" type="button" @click.prevent="onSubmit">
                <i class="fa-solid fa-check"></i>
                Confirmar
              </button>
              <button class="btn btn-danger" type="button" @click.prevent="show_modal_confirmar_crear = false">
                <i class="fa-solid fa-xmark"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <!--Modal Confirmar datos  -->

    </Modal>
  </div>
</template>

<script>
import apiCatalogos from '@/apps/condusef/api/catalogos';
import apiSepomex from '@/apps/condusef/api/sepomex';
import apiCondusefAclaraciones from '@/apps/condusef/api/aclaraciones';
import Modal from '@/components/Modal';
import IC from "@/components/Form/InputCompuesto.vue";

export default {
  components: { Modal, IC },
  props: {
    prop_aclaracion: {
      type: Object,
      default() {
        return {
          folio_atencion: null
        }
      }

    },
  },
  data() {
    return {
      aclaracion: {
        trimestre:  null,
        trimestre_ano:  null,
        numero_aclaracion:  1,
        estatus_aclaracion:  null,
        fecha_aclaracion:  null,
        fecha_atencion:  null,
        medio_de_recepcion:  null,
        producto_servicio:  null,
        causa:  null,
        fecha_resolucion:  null,
        fecha_notificacion_usuario:  null,
        entidad:  null,
        codigo_postal:  null,
        municipio :  null,
        localidad:  null,
        colonia:  null,
        aclaracion_monetario:  null,
        monto_reclamado:  null,
        prioridad:  null,
        tipo_persona:  null,
        sexo:  null,
        edad:  null,
        nivel_atencion:  null,
        folio_condusef:  null,
        reversaSIGE: null,
        operacion_extranjero: null
      },
      editable: false,
      show_editar: null,
      evento_id: null,
      pagina: 1,
      medios_recepciones: [],
      productos_servicios: [],
      causas_financieras: [],
      niveles_atenciones: [],
      colonias: [],
      estados: [],
      municipios: [],
      localidades: [],
      show_modal_confirmar_crear: false,
      estatus_concluido: 2,
      estatus_pendiente: 1,
      persona_fisica: 1,
      persona_moral: 2,
      trimestres:  {1:'ENERO-MARZO', 2:'ABRIL-JUNIO', 3:'JULIO-SEPTIEMBRE', 4:'NOVIEMBRE-DICIEMBRE'}

    }
  },
  computed: {
    ano_actual(){
      return this.$moment().year()
    },
    //El trimestre que se debe presentar siempre va a ser el anterior al actual
    trimestre_activo() {
      if (this.prop_aclaracion.folio_atencion)
        return {trimestre: this.prop_aclaracion.trimestre,  trimestre_ano: this.prop_aclaracion.trimestre_ano, label: this.prop_aclaracion.trimestre_label}


      const trimestre_actual = this.$moment().quarter()

      if (trimestre_actual === 1) {
        const trimestre_ano = this.ano_actual - 1;
        return {trimestre: 4, trimestre_ano: trimestre_ano, label: `${this.trimestres[4]} ${trimestre_ano}`}
      }

      const trimestre_activo = (trimestre_actual-1);

      return {
        trimestre: trimestre_activo, trimestre_ano: this.ano_actual,  label: `${this.trimestres[trimestre_activo]} ${this.ano_actual}`

      }
    }
  },
  mounted() {
    if (this.prop_aclaracion.folio_atencion) {
      this.aclaracion = this.$helper.clone(this.prop_aclaracion);
      this.editable = false;
      this.buscar_codigo_postal()
    } else {
      this.editable = true
      this.aclaracion.trimestre = this.trimestre_activo.trimestre
      this.aclaracion.trimestre_ano = this.trimestre_activo.trimestre_ano
    }

    this.obtener_catalogos();
  },
  methods: {
    anterior() {
      if (this.pagina > 1) {
        this.pagina--;
      }
    },
    siguiente() {
      if (this.pagina < 3) {
        this.pagina++;
      }
    },

    cambio_estatus_aclaracion(){
      // si el esaatus no esconcluido los siguientes campos no son requeridos (se pasán a nulo)
      /**if (this.aclaracion.estatus_aclaracion !== this.estatus_concluido)
        this.aclaracion.fecha_atencion = null
      this.aclaracion.nivel_atencion = null
          **/

      if (this.aclaracion.estatus_aclaracion == this.estatus_pendiente) {
        this.aclaracion.fecha_atencion = null
        this.aclaracion.nivel_atencion = null
        this.aclaracion.fecha_resolucion = null
        this.aclaracion.fecha_notificacion_usuario = null
      }
    }
    ,cambio_tipo_persona(){
      if (this.aclaracion.tipo_persona == this.persona_moral) {
        this.aclaracion.sexo = null;
        this.aclaracion.edad = null;
      }
  },
    async obtener_catalogos() {
      try {
        const catalogos = (await apiCatalogos.listar_catalogos_reune()).data;
        for (const catalogo of catalogos) {
          if (catalogo.codigo == 'medios_de_recepcion')
            this.medios_recepciones = catalogo.opciones;
          else if (catalogo.codigo == 'lista_de_productos_aclaraciones_reune')
            this.productos_servicios = catalogo.opciones;
          else if (catalogo.codigo == 'niveles_de_atencion')
            this.niveles_atenciones = catalogo.opciones;
        }
      } catch (error) {
        this.$log.error('error', error);
        this.$helper.showAxiosError(error, 'Error');
      }
    },

    async buscar_codigo_postal() {
      try {
        const response = (await apiSepomex.codigos_postales(this.aclaracion.codigo_postal)).data;
        this.colonias = response.colonias
        this.estados = response.estados
        this.municipios = response.municipios
        this.localidades = response.localidades
      } catch (error) {
        this.$log.error('error', error);
        this.$helper.showAxiosError(error, 'Error');
      }
    },
    async obtener_casuas(){

        this.causas_financieras = []

        const url_options = {
          filters: [{
            relacion: "and",
            columna: "codigo",
            operador: "equal_to",
            valor1: 'reune_aclaraciones_causas_producto_' + this.aclaracion.producto_servicio,
            valor2: null
          }]
        }
        const response = (await apiCatalogos.listar_catalogos(url_options)).data[0]
        this.causas_financieras = response.opciones

    },
    async onSubmit() {

      this.show_modal_confirmar_crear = false;
      try {
        const response = (await apiCondusefAclaraciones.crear(this.aclaracion)).data
        this.$helper.showMessage('Crear Aclaración', 'Aclaración guardada exitosamente.', 'success', 'alert')

        this.$log.info('response', response);
        this.$emit('update');
      } catch (error) {
        this.$log.error('error', error);
        this.$helper.showAxiosError(error, 'Error');
      }
    },
  },

  watch: {
    'aclaracion.producto_servicio': {
      handler(newValue, oldValue) {
        if (!!newValue && newValue !== oldValue) {

          if (oldValue !== null)
            this.aclaracion.causa = null

          this.obtener_casuas()
        }
      }
    }
    ,'aclaracion.aclaracion_monetario': {
      handler(newValue, oldValue) {
        if (!!newValue && newValue !== oldValue) {

          if (newValue === 'NO')
            this.aclaracion.monto_reclamado = null
        }
      }
    }
  }

};

</script>